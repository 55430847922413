import React, { useState, useEffect } from 'react';
import Img1 from '../images/Foto png/fletorree.JPG';
import Img2 from '../images/Foto png/flayera2.JPG';
import Img3 from '../images/Foto png/flayera3.JPG';
import Img4 from '../images/catt.JPG';
import Img5 from '../images/img/gewiss2.JPG';
import Img6 from '../images/Foto png/vizitkartaaa.JPG';
import Img7 from '../images/Foto png/vizitkarta4.JPG';
import Img8 from '../images/carddd.jpg';
import Img9 from '../images/img/DSC_0150.JPG';
import Img10 from '../images/Foto png/vizittt.JPG';
import Img11 from '../images/Foto png/vizitkarta3.JPG';
import Img12 from '../images/Foto png/vizitkarta2.JPG';
import Img13 from '../images/img/gewis.JPG';
import Img14 from '../images/Foto png/calendar egs2 .JPG';
import Img15 from '../images/img/gewiss3.JPG';
import Img16 from '../images/Foto png/vizitkarta1.JPG';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { experimentalStyled as styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import { Grid } from '@mui/material';
import './offset.css';

const productData = [
    {
        className: 'pr , imagess',
        id: 7,
        image: Img5,
    },
    {
        className: 'pr , imagess',
        id: 7,
        image: Img6,
    },
    {
        className: 'pr , imagess',
        id: 7,
        image: Img7,
    },
    {
        className: 'pr , imagess',
        id: 7,
        image: Img8,
    },
    {
        id: 2,
        image: Img1,
    },
    {
        id: 2,
        image: Img2,
    },
    {
        id: 2,
        image: Img3,
    },
    {
        id: 2,
        image: Img4,
    },
    {
        id: 2,
        image: Img9,
    },
    {
        id: 2,
        image: Img10,
    },
    {
        id: 2,
        image: Img11,
    },
    {
        id: 2,
        image: Img12,
    },
    {
        className: 'pr , imagess',
        id: 7,
        image: Img13,
    },
    {
        className: 'pr , imagess',
        id: 7,
        image: Img14,

    },
    {
        className: 'pr , imagess',
        id: 7,
        image: Img15,
    },
    {
        className: 'pr , imagess',
        id: 7,
        image: Img16,
    },
    // Add more products here
];
const Item = styled(Paper)(({ theme }) => ({
    // backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    // ...theme.typography.body2,
    // padding: theme.spacing(2),
    // textAlign: 'center',
    // color: theme.palette.text.secondary,
    // transition: 'opacity 0.5s ease-in-out', // Add a transition effect
    // border: 'none',
}));

// ... (other imports)

function Offset() {
    const [productsVisible, setProductsVisible] = useState(false);

    useEffect(() => {
        // Set products to be visible after 0.5 seconds
        const timer = setTimeout(() => {
            setProductsVisible(true);
        }, 500);

        // Clear the timer to avoid memory leaks
        return () => clearTimeout(timer);
    }, []);

    return (
        <div className="container">

            <h1 className='h11'>OFFSET PRINTING</h1>

            <Grid container spacing={1} className='grid'>
                <TransitionGroup component={null}>
                    {productData.map((product) => {
                        const isStyledProduct = [5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18].includes(product.id);
                        const productClasses = isStyledProduct ? 'product-grid isStyledProduct' : 'product-grid';

                        return (
                            <CSSTransition
                                key={product.image}
                                timeout={500}
                                classNames="fade"
                                appear={true}
                            >
                                <Grid item xs={12} sm={12} md={4} lg={3}>
                                    <div className={productClasses}>
                                        <div className={`grid-content ${productsVisible ? 'visible' : ''}`}>
                                            <div className='grid-items'>
                                                <img
                                                    src={product.image}
                                                    alt='img'
                                                    id="imgg"
                                                    style={{
                                                        height: isStyledProduct ? '220px' : '100%',
                                                        opacity: productsVisible ? 1 : 0,
                                                    }}
                                                />
                                                {/* Add your Typography components if needed */}
                                            </div>
                                        </div>
                                    </div>
                                </Grid>
                            </CSSTransition>
                        );
                    })}
                </TransitionGroup>
            </Grid>
        </div>
    );
}

export default Offset;
