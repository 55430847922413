import React, { useRef } from 'react';
import { experimentalStyled as styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import { Typography } from '@mui/material';
import './Products.css';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { FaTshirt } from "react-icons/fa";
import { FaVideo } from 'react-icons/fa';
import { FaIdCard } from 'react-icons/fa';
import { FaBookOpen } from 'react-icons/fa';
// import { FaDesktop } from 'react-icons/fa';
import { FaBook } from 'react-icons/fa';
// import { FaCodepen } from 'react-icons/fa';
import { FaVest } from 'react-icons/fa';
// import { FaCarSide } from 'react-icons/fa';
import { FaStore } from 'react-icons/fa';
import { FaScroll } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';



//code for products
const productData = [
    {
        id: 1,
        icon: <FaTshirt size={90} style={{ color: "#98C22A" }} />,
        title: ' SWEATSHIRTS ',
        description: 'Description for Product 2Description for Product 2Description for Product 2', button: 'VIEW MORE',
        button: (
            <Link to="/categories/Textile" id='li'>VIEW MORE</Link>
        ),
        customClass: 'sweatshirts-product', // Add a custom class for the product
        additionalClasses: {
            // Additional classes for the Item component
            // Add more styles as needed

        },
    },
    {
        id: 2,
        icon: <FaTshirt size={90} style={{ color: "#98C22A" }} />,
        title: 'T-SHIRTS',
        description: 'Description for Product 2Description for Product 2Description for Product 2',
        button: (
            <Link to="/categories/Textile" id='li'>VIEW MORE</Link>
        ),
    },
    {
        id: 3,
        icon: <FaVideo size={90} style={{ color: "#98C22A" }} />,
        title: 'VIDEO EDITING',
        description: 'Description for Product 2Description for Product 2Description for Product 2',
        button: (
            <Link to="/categories/DMarketing" id='li'>VIEW MORE</Link>
        ),
    },
    {
        id: 4,
        icon: <FaIdCard size={90} style={{ color: "#98C22A" }} />,
        title: 'BUSINESS CARDS',
        description: 'Description for Product 2Description for Product 2Description for Product 2',
        button: (
            <Link to="/categories/DigitalPrinting" id='li'>VIEW MORE</Link>
        ),
    },
    {
        id: 5,
        icon: <FaBookOpen size={90} style={{ color: "#98C22A" }} />,
        title: 'CATALOGS',
        description: 'Description for Product 2Description for Product 2Description for Product 2',
        button: (
            <Link to="/categories/DigitalPrinting" id='li'>VIEW MORE</Link>
        ),
    },
    // {
    //     id: 6,
    //     icon: <FaDesktop size={90} style={{ color: "#98C22A" }} />,
    //     title: 'DESIGN & PRINT',
    //     description: 'Description for Product 2Description for Product 2Description for Product 2',
    //     button: (
    //         <Link to="/categories/DMarketing" id='li'>VIEW MORE</Link>
    //     ),
    // },
    {
        id: 7,
        icon: <FaBook size={90} style={{ color: "#98C22A" }} />,
        title: 'NOTEBOOKS',
        description: 'Description for Product 2Description for Product 2Description for Product 2',
        button: 'VIEW MORE',
    },
    {
        id: 8,
        icon: <FaScroll size={90} style={{ color: "#98C22A" }} />,
        title: 'Vinyl Wrapping',
        description: 'Description for Product 2Description for Product 2Description for Product 2',
        button: (
            <Link to="/categories/Vinyl" id='li'>VIEW MORE</Link>
        ),
    },
    {
        id: 9,
        icon: <FaVest size={90} style={{ color: "#98C22A" }} />,
        title: 'VESTS',
        description: 'Description for Product 2Description for Product 2Description for Product 2',
        button: (
            <Link to="/categories/Textile" id='li'>VIEW MORE</Link>
        ),
    },
    // {
    //     id: 10,
    //     icon: <FaCodepen size={90} style={{ color: "#98C22A" }} />,
    //     title: '3D SIGNS & BILLBOARDS',
    //     description: 'Description for Product 2Description for Product 2Description for Product 2',
    //     button: (
    //         <Link to="/categories/Signss" id='li'>VIEW MORE</Link>
    //     ),
    // },
    {
        id: 11,
        icon: <FaStore size={90} style={{ color: "#98C22A" }} />,
        title: 'SOTRE ADDVERTISTING',
        description: 'Description for Product 2Description for Product 2Description for Product 2',
        button: (
            <Link to="/categories/Vinyl" id='li'>VIEW MORE</Link>
        ),
    },
    // {
    //     id: 12,
    //     icon: <FaCarSide size={90} style={{ color: "#98C22A" }} />,
    //     title: 'CAR WRAPPING',
    //     description: 'Description for Product 2Description for Product 2Description for Product 2',
    //     button: (
    //         <Link to="/car" id='li'>VIEW MORE</Link>
    //     ),
    // },
    // Add more products here
];


// ... (your imports)



// Modified Item component to accept additional classes
const Item = styled(Paper)(({ theme, additionalClasses }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    // Add custom styles and additional classes
    ...additionalClasses,
}));

export default function ResponsiveGrid() {
    const sliderRef = useRef(null);

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 6, // Show 6 products at a time
        slidesToScroll: 1,
        centerMode: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3, // Show 3 products at a time on smaller screens
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1, // Show 1 product at a time on even smaller screens
                },
            },
        ],
    };

    const handleCardClick = (index) => {
        sliderRef.current.slickGoTo(index);
    };

    return (
        <section className='Products' id='Products'>
            <div className="title">
                <h1>OUR WORK</h1>
            </div>
            <div className="slider-container" >
                <Slider {...settings} ref={sliderRef}>
                    {productData.map((product, index) => (
                        <div className="cards" key={product.id} >
                            <div
                                className={`card ${product.customClass}`}
                                onClick={() => handleCardClick(index)}
                            >
                                <Item id='items' additionalClasses={product.additionalClasses} >
                                    <icon id="picon"> {product.icon} </icon>
                                    <div className='line'></div>
                                    <Typography gutterBottom variant='h5' component="div" id='ptitle'>
                                        {product.title}
                                    </Typography><br></br>
                                    {/* <Typography gutterBottom variant='p' id='paragraph'>
                                        {product.description}
                                    </Typography> */}
                                    <button className='productbtn'>
                                        {product.button}
                                    </button>
                                </Item>
                            </div>
                        </div>
                    ))}
                </Slider>
            </div>
        </section>
    );
}
