
import React from 'react';
import './categories/Footer.css';
import { FaPhone, FaInstagram, FaTiktok, FaFacebook, FaEnvelope } from 'react-icons/fa';
import { Link } from 'react-router-dom';
// import { GoogleFontsProps } from 'react-google-fonts';
import { Helmet } from 'react-helmet';


function Footer() {
    return (
        <div className="FooterContainer" id='footer'>
            <div className="fcontent">
                <div className="contactt">
                    <a href="tel:+38975665066" id='linksf'>
                        <FaPhone size={25} style={{ color: "#98C22A" }} id='phoneicon' />+389 75 665 066
                    </a>
                    <Link id='email'>
                        <a id='linksf'> warakahdesign@gmail.com </a>
                        <FaEnvelope size={25} style={{ color: "#98C22A" }} /></Link>
                </div>
                <div className="social">
                    <h3><a href="https://www.instagram.com/warakahdesign" id='linksf'>
                        <FaInstagram size={30} style={{ color: '#98C22A' }} />
                    </a>
                    </h3>
                    <h3><a href="https://www.facebook.com/warakahdesign" id='linksf'>
                        <FaFacebook size={30} style={{ color: '#98C22A' }} />
                    </a>
                    </h3>
                    <h3><a href="https://www.tiktok.com/@warakahdesign" id='linksf'>
                        <FaTiktok size={30} style={{ color: '#98C22A' }} />
                    </a>
                    </h3>
                </div>
                <div id='textt'>Working Quality is our Priority</div>
            </div>
            <Helmet>
                <link
                    rel="preconnect"
                    href="https://fonts.gstatic.com"
                />
                <link
                    href="https://fonts.googleapis.com/css2?family=Herr+Von+Muellerhoff&display=swap"
                    rel="stylesheet"
                />
            </Helmet>
        </div>
    );
}




export default Footer;