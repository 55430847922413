import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../components/categories/Mainn.css';
import slide1 from '../components/images/Foto png/Jumper.png';
import slide2 from '../components/images/Foto png/cardd.png';
import slide3 from '../components/images/Foto png/duks.png';
import slide4 from '../components/images/Foto png/katalog.png';
import slide5 from '../components/images/Foto png/maic.png';
import slide6 from '../components/images/Foto png/notebook.png';
import slide7 from '../components/images/Foto png/vestt.png';
import slide8 from '../components/images/Foto png/Jumper.png';
import slide9 from '../components/images/Foto png/logo.png';

function Main() {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2300,
        responsive: [
            {
                breakpoint: 768, // Adjust this value based on your design needs
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true,
                },
            },
        ],
    };

    return (
        <section id='main' className='Main'>
            <Slider {...settings} className='Main'>
                <div>
                    <img src={slide1} alt="Slide 1" className='sl' />
                </div>
                <div>
                    <img src={slide2} alt="Slide 2" className='sl' />
                </div>
                <div>
                    <img src={slide3} alt="Slide 3" className='sl' />
                </div>
                <div>
                    <img src={slide4} alt="Slide 4" className='sl' />
                </div>
                <div>
                    <img src={slide5} alt="Slide 5" className='sl' />
                </div>
                <div>
                    <img src={slide6} alt="Slide 6" className='sl' />
                </div>
                <div>
                    <img src={slide7} alt="Slide 7" className='sl' />
                </div>
                <div>
                    <img src={slide8} alt="Slide 8" className='sl' />
                </div>
                <div>
                    <img src={slide9} alt="Slide 9" className='sl' />
                </div>
                {/* Add more slides as needed */}
            </Slider>
        </section>
    );
}

export default Main;
