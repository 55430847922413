import React, { useEffect } from 'react';
import './About.css';
import LLogo from './images/LOGOO.png';
import profile from './images/astrit.jpg';
import 'animate.css';


function About() {
    useEffect(() => {
        const astritObserver = new IntersectionObserver(
            (entries, observer) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        entry.target.classList.add('show');
                        observer.unobserve(entry.target);
                    }
                });
            },
            { threshold: 0.3 } // Adjust as needed
        );


        const astritElements = document.querySelectorAll('.Astrit');
        astritElements.forEach((element) => {
            astritObserver.observe(element);
        });

        return () => {
            astritElements.forEach((element) => {
                astritObserver.unobserve(element);
            });
        };
    }, []);
    return (
        <section className="About" id='about'>

            <div className="about-container">

                <div className='contentt'>

                    <div className="abouttext">
                        <div className="titlee">
                            <h1>ABOUT</h1>
                        </div>
                        <p>
                            <h3 className='h3'>About Warakah Design & Print</h3>

                            Welcome to Warakah Design & Print, your one-stop destination for all your printing needs and creative solutions. Established in April 2023 by the visionary founder, Astrit Trstena, Warakah Design & Print has quickly risen to become a name synonymous with excellence, innovation, and artistic brilliance.

                            At Warakah, we're more than just a printing company; we're a creative hub that breathes life into your ideas and brings your vision to reality. With a passion for design and an unwavering commitment to quality, we offer a comprehensive range of services, catering to your every printing and creative requirement.


                            <h3 className='h3'>Why Choose Us</h3>

                            <strong id='subtitle'> Quality Assurance:</strong>We take pride in our commitment to delivering products and services of the highest quality. Each project goes through rigorous quality checks to ensure it meets our standards of excellence.

                            <strong id='subtitle'>Innovation:</strong>We keep a keen eye on the latest design and technology trends to bring fresh, innovative solutions to your projects. Our team is always eager to embrace new challenges and push creative boundaries.

                            <strong id='subtitle'>Customer-Centric Approach:</strong>Your satisfaction is our priority. We work closely with you to understand your needs and provide tailored solutions. Your success is our success.

                            <strong id='subtitle'>Timely Delivery:</strong>We value your time, and we're committed to delivering your projects promptly. We understand that time is of the essence, and we strive to meet your deadlines.

                            Discover the world of creative printing, design, and digital solutions with Warakah Design & Print. Join us in making your ideas come to life. We invite you to explore our services and be a part of our ever-growing family of satisfied customers.

                            Contact us today and let your creativity find its voice with Warakah Design & Print.
                        </p>
                    </div>

                    <div className="Astrit">
                        <div className="animate__animated animate__fadeInLeft">
                            <img src={profile} className='profile' alt='profile'></img>
                        </div>

                        <div className="ab animate__animated animate__fadeInRight">
                            <h1 className='ast'>Astrit Trstena</h1>
                            <p className='trit'> ( Chief Executive Officer )</p>
                            <p className='astritab'> Astrit Trstena, the CEO of Warakah Design & Print, is a visionary leader driving the company towards innovation and excellence in the field of design and printing. With a passion for creativity and a keen understanding of market trends, Astrit founded Warakah Design & Print in 2022, aiming to provide cutting-edge solutions in the ever-evolving world of design and print services.
                                <br></br><br></br>
                                Under Astrit's leadership, Warakah Design & Print has become synonymous with quality, reliability, and customer satisfaction. The company thrives on a commitment to delivering tailored solutions that meet the unique needs of its clients. Astrit's strategic vision and hands-on approach have played a pivotal role in establishing Warakah Design & Print as a trusted partner for individuals and businesses seeking top-notch design and printing services.
                                <br></br><br></br>
                                With a foundation built on creativity, professionalism, and a customer-centric ethos, Astrit continues to guide Warakah Design & Print towards new heights. His leadership style fosters a culture of innovation, teamwork, and continuous improvement, ensuring that the company stays at the forefront of industry trends and technological advancements.
                                <br></br><br></br>
                                Astrit Trstena's dedication to excellence and his forward-thinking approach make him a driving force behind the success of Warakah Design & Print, a company that is not only a beacon of creativity but also a reliable partner for those looking to make a lasting impression through design and print.</p>
                        </div>
                    </div>

                    <div className='abouttext'>
                        <h3 className='h3'> Our Services</h3>

                        <p>
                            <strong id='subtitle'>Printing Services:</strong> At the heart of our business, we pride ourselves on delivering high-quality printing solutions that make your projects stand out. Whether you're in need of business cards, brochures, posters, or custom stationery, we have you covered. Our state-of-the-art printing technology ensures vibrant colors and precise detailing on every project.

                            <strong id='subtitle'>Video Editing:</strong> In the digital age, video content is king. We understand the power of visual storytelling and offer professional video editing services to make your videos shine. From promotional videos to educational content, we transform raw footage into engaging, polished masterpieces.

                            <strong id='subtitle'>Logo Animation:</strong>Your logo is the face of your brand, and we know how to make it come alive. Our skilled team can create captivating logo animations that add an extra dimension to your brand identity. A dynamic logo not only grabs attention but also leaves a lasting impression.

                            <strong id='subtitle'>Webpages:</strong>In a world driven by digital presence, a compelling website is a necessity. Our web design and development team is adept at creating functional, user-friendly, and visually appealing websites. We understand the importance of a strong online presence and offer custom solutions to help your brand thrive in the virtual landscape.

                            <h3 className='h3'>Our Vision</h3>

                            Our vision is to be the creative partner you can rely on. We believe that every project is a canvas waiting to be painted with innovation, and we're here to provide the brushstrokes. With a commitment to quality, timeliness, and a touch of artistic flair, we aim to exceed your expectations every time.


                        </p>
                    </div>

                    <div className="image">
                        <img src={LLogo} alt='logo' id='image' ></img>
                    </div>
                </div>
            </div>

        </section>
    )
}

export default About;
