import React, { useState, useEffect } from 'react';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { experimentalStyled as styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import { Grid } from '@mui/material';
import './offset.css';
import Img3 from '../images/xhemperanesh.JPG';
import Img5 from '../images/benijakne.JPG';
import Img4 from '../images/xhemperaneshh.JPG';
import Img6 from '../images/jakne.JPG';
import Img7 from '../images/vennz.JPG';
import Img8 from '../images/venz.JPG';
import Img9 from '../images/afo.JPG';
import Img10 from '../images/afoo.JPG';
import Img11 from '../images/w.JPG';
import Img12 from '../images/war.JPG';
import Img13 from '../images/waraka.JPG';
import Img14 from '../images/duks.JPG';
import Img15 from '../images/mettin.JPG';
import Img16 from '../images/metinnn.JPG';
import Img17 from '../images/duksz.JPG';
import Img18 from '../images/duuuks.JPG';
import Img19 from '../images/meddii.JPG';
import Img20 from '../images/medi.JPG';
import Img21 from '../images/ornato.JPG';
import Img22 from '../images//lskkkkk.JPG';
import Img23 from '../images/royalll.jpg';
import Img24 from '../images/royallll.jpg';
import Img25 from '../images/cityb.jpg';
import Img26 from '../images/cityk.jpg';



const productData = [
    {
        id: 1,
        image: Img3,
        title: 'SWEATSHIRTS',
        description: 'Description for Product 1',
    },
    {
        id: 2,
        image: Img4,
        title: 'SWEATSHIRTS',
        description: 'Description for Product 1',
    },
    {
        id: 3,
        image: Img5,
        title: 'SWEATSHIRTS',
        description: 'Description for Product 1',
    },
    {
        id: 4,
        image: Img6,
        title: 'SWEATSHIRTS',
        description: 'Description for Product 1',
    },
    {
        id: 5,
        image: Img7,
        title: 'T-SHIRTS',
        description: 'Description for Product 1',
    },
    {
        id: 6,
        image: Img8,
        title: 'T-SHIRTS',
        description: 'Description for Product 1',
    },

    {
        id: 7,
        image: Img9,
        title: 'T-SHIRTS',
        description: 'Description for Product 1',
    },

    {
        id: 8,
        image: Img10,
        title: 'T-SHIRTS',
        description: 'Description for Product 1',
    },
    {
        id: 9,
        image: Img11,
        title: 'T-SHIRTS',
        description: 'Description for Product 1',
    },
    {
        id: 10,
        image: Img12,
        title: 'T-SHIRTS',
        description: 'Description for Product 1',
    },
    {
        id: 11,
        image: Img13,
        title: 'T-SHIRTS',
        description: 'Description for Product 1',
    },
    {
        id: 12,
        image: Img14,
        title: 'SWEATSHIRTS',
        description: 'Description for Product 1',
    },
    // {
    //     id: 13,
    //     image: Img15,
    //     title: 'SWEATSHIRTS',
    //     description: 'Description for Product 1',
    // },

    // {
    //     id: 14,
    //     image: Img16,
    //     title: 'SWEATSHIRTS',
    //     description: 'Description for Product 1',
    // },

    // {
    //     id: 15,
    //     image: Img17,
    //     title: 'SWEATSHIRTS',
    //     description: 'Description for Product 1',
    // },

    // {
    //     id: 16,
    //     image: Img18,
    //     title: 'SWEATSHIRTS',
    //     description: 'Description for Product 1',
    // },

    {
        id: 17,
        image: Img19,
        title: 'VESTS',
        description: 'Description for Product 1',
    },

    {
        id: 18,
        image: Img20,
        title: 'VESTS',
        description: 'Description for Product 1',
    },
    {
        id: 19,
        image: Img21,
        title: 'VESTS',
        description: 'Description for Product 1',
    },
    {
        id: 20,
        image: Img22,
        title: 'VESTS',
        description: 'Description for Product 1',
    },

    {
        id: 22,
        image: Img23,
        title: 'VESTS',
        description: 'Description for Product 1',
    },


    {
        id: 23,
        image: Img24,
        title: 'VESTS',
        description: 'Description for Product 1',
    },

    {
        id: 24,
        image: Img25,
        title: 'T-SHIRTS',
        description: 'Description for Product 2',
    },
    {
        id: 25,
        image: Img26,
        title: 'T-SHIRTS',
        description: 'Description for Product 2',
    },

];

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    transition: 'opacity 0.5s ease-in-out', // Add a transition effect
    border: 'none',
}));

// ... (other imports)

function Textile() {
    const [productsVisible, setProductsVisible] = useState(false);

    useEffect(() => {
        // Set products to be visible after 0.5 seconds
        const timer = setTimeout(() => {
            setProductsVisible(true);
        }, 500);

        // Clear the timer to avoid memory leaks
        return () => clearTimeout(timer);
    }, []);

    return (
        <div className="container">
            <h1 className='h11'>TEXTILE</h1>

            <Grid container spacing={1} className='grid'>
                <TransitionGroup component={null}>
                    {productData.map((product) => {
                        const isStyledProduct = [5, 6, 7, 8, 14, 15, 16, 17, 18].includes(product.id);
                        const productClasses = isStyledProduct ? 'product-grid isStyledProduct' : 'product-grid';

                        return (
                            <CSSTransition
                                key={product.image}
                                timeout={500}
                                classNames="fade"
                                appear={true}
                            >
                                <Grid item xs={12} sm={12} md={4} lg={3}>
                                    <div className={productClasses}>
                                        <div className={`grid-content ${productsVisible ? 'visible' : ''}`}>
                                            <div className='grid-items'>
                                                <img
                                                    src={product.image}
                                                    alt='img'
                                                    id="imgg"
                                                    style={{
                                                        opacity: productsVisible ? 1 : 0,
                                                    }}
                                                />
                                                {/* Add your Typography components if needed */}
                                            </div>
                                        </div>
                                    </div>
                                </Grid>
                            </CSSTransition>
                        );
                    })}
                </TransitionGroup>
            </Grid>
        </div>
    );
}

export default Textile;
