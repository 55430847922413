import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Main from './components/Main';
import Products from './components/Products';
import Footer from './components/Footer';
import Services from './components/Services';
import About from './components/About';
import Contact from './components/Contact-us';
import './components/Transitions.css';
import Offset from './components/categories/Offset';
import DigitalPrinting from './components/categories/DigitalPrinting'
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import Textile from './components/categories/Textile';
// import Signs from './components/categories/Signss';
// import Car from './components/categories/Car';
// import DM from './components/categories/DMarketing';
import Vinyll from './components/categories/Vinyl';
import ScrollToTop from './ScrollTop'; // Import the ScrollToTop component
import Catalogs from './components/catalogs/catalogs.js';


function App() {
  return (
    <Router>
      <Header />
      <ScrollToTop /> {/* Add ScrollToTop component here */}
      <TransitionGroup component={null}>
        <CSSTransition
          key={window.location.key}
          classNames="page"
          timeout={500}
          id="transition"
        >
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/Services" element={<Services />} />
            <Route path="/About" element={<About />} />
            <Route path="/Contact-us" element={<Contact />} />
            <Route path="/categories/Offset" element={<Offset />} />
            <Route path="/categories/DigitalPrinting" element={<DigitalPrinting />} />
            {/* <Route path="/categories/DMarketing" element={<DM />} /> */}
            <Route path="/categories/Textile" element={<Textile />} />
            <Route path="/categories/Vinyl" element={<Vinyll />} />
            {/* <Route path="/categories/Signss" element={<Signs />} /> */}
            {/* <Route path="/categories/Car" element={<Car />} /> */}
            <Route path="./components/catalogs/catalogs.js" element={<Catalogs />} />
          </Routes>
        </CSSTransition>
      </TransitionGroup>
    </Router>
  );
}

function Home() {
  return (
    <>
      <Header></Header>
      <Main />
      <Products />
      <Footer />
    </>
  );
}

export default App;
