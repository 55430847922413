import React, { useRef } from 'react';
import './Contact.css';
import { FaPhone, FaInstagram, FaTiktok, FaFacebook, FaEnvelope, FaWhatsapp } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import emailjs from 'emailjs-com';

function Contact() {
    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_ufq7rxe', 'template_t7a71tm', form.current, '3AryzNIiJi4WspPob')
            .then((result) => {
                console.log(result.text);
            })
            .catch((error) => {
                console.log(error.text);
            })
            .finally(() => {
                // Reset the form after submission
                form.current.reset();
            });
    };

    return (
        <div className='map'>
            <div className="containerr">
                <div className='title'>
                    <h2> FIND OUR LOCATION</h2>
                </div>
                <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2963.9583367272517!2d21.430627576793313!3d42.02262617122634!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x13541588bd6603ed%3A0xdfca9cdee4742ea2!2sWarakah%20Design%20%26%20Print!5e0!3m2!1sen!2smk!4v1697440397186!5m2!1sen!2smk"
                    width="100%"
                    height="750"
                    style={{ border: "0" }}
                    allowFullScreen=""
                    loading="lazy"
                    className='mapss'
                ></iframe>
            </div>


            <div className="FooterContainerr" id='footer'>
                <div className="titlee">
                    <h1>CONTACT</h1>
                </div>
                <div className="fcontentt">
                    <div className="contactt">

                        <form ref={form} onSubmit={sendEmail} className="contact-form">
                            <label htmlFor="user_name">Name</label>
                            <input type="text" name="user_name" id="user_name" />

                            <label htmlFor="user_email">Email</label>
                            <input type="email" name="user_email" id="user_email" />

                            <label htmlFor="message">Message</label>
                            <textarea name="message" id="message"></textarea>

                            <button type="submit">Send Message</button>
                        </form>
                    </div>
                    <div className="contact">
                        <p id='footert'>
                            <a href="tel:+38975665066" id="callLink">
                                +389 75 665 066<FaPhone size={25} style={{ color: "#98C22A" }} id='phoneicon' />
                            </a>
                        </p>

                        <Link id='email'>
                            <a id='callLink' href="mailto:warakahdesign@gmail.com">
                                warakahdesign@gmail.com <FaEnvelope size={25} style={{ color: "#98C22A" }} />
                            </a>
                        </Link>

                        <div className="social">
                            <h3>
                                <a href="https://www.instagram.com/warakahdesign" id='linksf'>
                                    <FaInstagram size={30} style={{ color: '#98C22A' }} className="linksf" />
                                </a>
                            </h3>
                            <h3>
                                <a href="https://www.facebook.com/warakahdesign" id='linksf'>
                                    <FaFacebook size={30} style={{ color: '#98C22A' }} className="linksf" />
                                </a>
                            </h3>
                            <h3>
                                <a href="https://www.tiktok.com/@warakahdesign" id='linksf'>
                                    <FaTiktok size={30} style={{ color: '#98C22A' }} className="linksf" />
                                </a>
                            </h3>
                            <h3>
                                <a href="https://wa.me/38975665066" target="_blank" rel="noopener noreferrer">
                                    <FaWhatsapp size={30} style={{ color: '#98C22A' }} />
                                </a>
                            </h3>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
}

export default Contact;
