import React, { useState, useEffect, useRef } from 'react';
import Logo from './images/Logo.png';
import './Header.css';
import { Link } from 'react-router-dom';
import { FaSortDown } from 'react-icons/fa';
import 'animate.css';
import Click from '../assets/ClickSound .mp3';
import Catalog from './catalogs/Web Catalog.pdf';

function Header() {
    const [navbarActive, setNavbarActive] = useState(false);
    const [categoriesVisible, setCategoriesVisible] = useState(false);
    const [catalogsVisible, setCatalogsVisible] = useState(false);
    const [mobileMenuVisible, setMobileMenuVisible] = useState(false);
    const audioRef = useRef(null);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 0) {
                setNavbarActive(true);
            } else {
                setNavbarActive(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const toggleCategoriesDropdown = () => {
        setCategoriesVisible(!categoriesVisible);
        setCatalogsVisible(false); // Hide the other dropdown
    };

    const toggleCatalogsDropdown = () => {
        setCatalogsVisible(!catalogsVisible);
        setCategoriesVisible(false); // Hide the other dropdown
    };

    const toggleMobileMenu = () => {
        setMobileMenuVisible(!mobileMenuVisible);
    };

    const playSound = () => {
        // Reset and play the audio
        audioRef.current.currentTime = 0;
        audioRef.current.play();
    };

    return (
        <div className={`content ${navbarActive ? 'active' : ''}`}>
            <Link to='/'>
                <img src={Logo} alt='logo' id='logo' />
            </Link>
            <button className="mobile-menu-toggle" onClick={toggleMobileMenu}>
                ☰
            </button>
            <audio ref={audioRef} src={Click} preload="auto" style={{ display: 'none' }} />
            <ul className={`mobile-menu ${mobileMenuVisible ? 'visible' : ''}`}>
                <li>
                    <Link to='/' onClick={playSound}>
                        HOME
                    </Link>
                </li>
                <li>
                    <Link to='./Services' onClick={playSound}>
                        SERVICES
                    </Link>
                </li>
                <li>
                    <Link to='./About' onClick={playSound}>
                        ABOUT
                    </Link>
                </li>
                <li>
                    <div
                        className={`dropdown ${categoriesVisible ? 'activee' : ''}`}
                        onClick={() => {
                            toggleCategoriesDropdown();
                            playSound();
                        }}
                    >
                        <FaSortDown size={14} />
                        <Link onClick={playSound}>CATEGORIES</Link>
                        {categoriesVisible && (
                            <ul className={"dropdown-content"} id='drop' onClick={playSound}>
                                <li><Link to='./categories/DigitalPrinting' id='links'>DIGITAL<br></br>PRINTING</Link></li>
                                <li><Link to='./categories/Offset' id='links'>OFFSET<br></br>PRINTING</Link></li>
                                <li><Link to='./categories/Textile' id='links'>TEXTILE</Link></li>
                                <li><Link to='./categories/Vinyl' id='links'>WRAPPING</Link></li>
                            </ul>
                        )}
                    </div>
                </li>
                <li>
                    <div
                        className={`dropdown ${catalogsVisible ? 'activee' : ''}`}
                        onClick={() => {
                            toggleCatalogsDropdown();
                            playSound();
                        }}
                    >
                        <FaSortDown size={14} />
                        <Link onClick={playSound}>CATALOGS</Link>
                        {catalogsVisible && (
                            <ul className={"dropdown-content"} id='drop' onClick={playSound}>
                                <li><Link to={Catalog} target="_blank" onClick={playSound} className='catalog'>CATALOG 2024</Link></li>
                            </ul>
                        )}
                    </div>
                </li>
                <li>
                    <Link to='./Contact-us' onClick={playSound}>
                        CONTACT US
                    </Link>
                </li>
            </ul>
        </div>
    );
}

export default Header;
