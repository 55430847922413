import React, { useEffect } from 'react';
import './Services.css'
import Digital from './images/dp.png'
import Offset from './images/ofset.jpg'
import Web from './images/computer.jpeg'
import AOS from 'aos';
import 'aos/dist/aos.css';


function Services() {
    useEffect(() => {
        AOS.init({
            duration: 1500, // Set the duration of the animation
        });
    }, []);

    return (
        <section className="serv" id="Services">
            <div className="title" id='title'>
                <h6 className='sh' data-aos="flip-down">SERVICES</h6>
            </div>
            <div className="container">

                <div className="digitalprint">
                    <img src={Digital} alt='digital' id='digitalimg' data-aos="fade-right"
                        data-aos-offset="300"
                        data-aos-easing="ease-in-sine"></img>
                    <div className="texts">
                        <h1 className='servicetitle'> DIGITAL PRINTING</h1>
                        <p className='description' data-aos="fade-left"
                            data-aos-anchor="#example-anchor"
                            data-aos-offset="1500"
                            data-aos-duration="1500">
                            Our Digital Printing Service covers a spectrum of products, including eye-catching catalogs, professional business cards, attention-grabbing flyers, and personalized calendars. Whether you need marketing collateral for your business, promotional materials for an event, or custom-printed items for personal use, our digital printing capabilities ensure exceptional results.
                            <br></br> <br></br>
                            <strong>Key Features of Our Digital Printing Service:</strong>
                            <br></br> <br></br>
                            <strong>1.</strong>High-Quality Imaging: We employ advanced digital printers that deliver sharp, detailed, and true-to-life images, ensuring that your printed materials make a lasting impression.
                            <br></br> <br></br>
                            <strong>2.</strong>Versatility in Printing Options: From various paper types and sizes to finishes and coatings, our service provides flexibility to match your specific requirements. We offer a diverse range of printing options to enhance the visual appeal of your materials.
                            <br></br> <br></br>
                            <strong>3.</strong>Quick Turnaround: With digital printing, we can produce your prints quickly without compromising quality. This is especially beneficial for projects with tight deadlines or last-minute printing needs.
                            <br></br> <br></br>
                            <strong>4.</strong>Cost-Effective Solutions: Digital printing eliminates the need for costly setup processes required in traditional printing methods, making it a cost-effective choice for both small and large print runs.
                            <br></br> <br></br>
                            <strong>5.</strong>Customization: Tailor your prints to suit your unique style and purpose. Whether you're aiming for a sleek and professional look or a creative and artistic design, our digital printing service provides the customization options you need.
                            <br></br> <br></br>
                            <strong>6.</strong>Environmentally Friendly: Our commitment to sustainability is reflected in our use of eco-friendly inks and practices, minimizing environmental impact without sacrificing print quality.
                            <br></br> <br></br>
                            At Warakah Design & Print, we combine innovation, creativity, and attention to detail to bring your vision to life. Our Digital Printing Service empowers businesses, individuals, and organizations to make a bold statement through visually striking and professionally executed printed materials. Elevate your brand and communication with our reliable and top-notch digital printing solutions.
                        </p>
                    </div>
                </div>
                <div className="offsetprint">
                    <img src={Offset} alt='digital' id='offset' data-aos="fade-up"
                        data-aos-anchor-placement="center-bottom"></img>
                    <div className="texts">
                        <h1 className='servicetitle'> OFFSET PRINTING</h1>
                        <p className='description' data-aos="fade-up"
                            data-aos-anchor-placement="center-bottom">
                            Discover precision and quality with our Offset Printing Service at Warakah Design & Print. As industry leaders, we bring a seamless blend of tradition and innovation to meet your printing needs.

                            Offset printing is the cornerstone of our comprehensive services, renowned for its unmatched print quality. Perfect for large-scale projects, this cost-effective method ensures sharp, vibrant, and detailed prints, making it an ideal choice for brochures, catalogs, and business cards.
                            <br></br><br></br>
                            What sets our Offset Printing Service apart is our commitment to customization. We collaborate closely with clients, tailoring every aspect to their unique vision. From paper selection to finishes and color matching, we ensure your project stands out.

                            Versatility is key – our offset printing accommodates various paper types and thicknesses, adapting to diverse industries. Moreover, its eco-friendly approach, using soy-based inks and minimizing waste, aligns with our dedication to sustainability.

                            Our skilled technicians oversee the entire process, maintaining stringent quality control. With quick turnarounds and efficient workflows, Warakah Design & Print ensures your projects are delivered promptly without compromising the excellence we're known for.
                            <br></br><br></br>
                            For impactful, high-quality prints that leave a lasting impression, choose Warakah Design & Print's Offset Printing Service. Your vision, our expertise – the perfect print partnership.

                        </p>
                    </div>
                </div>
                <div className="web">
                    <img src={Web} alt='digital' id='web' data-aos="fade-up"
                        data-aos-anchor-placement="center-bottom"></img>
                    <div className="texts">
                        <h1 className='servicetitle'>WEB DESIGN % DEVELOPMENT</h1>
                        <p className='description' data-aos="fade-up"
                            data-aos-anchor-placement="center-bottom">
                            Warakah Design & Print offers cutting-edge Web Design & Development services that transcend conventional boundaries, bringing your brand to life in the digital realm. In the dynamic landscape of online presence, our skilled team ensures your website is not just a digital address but a captivating experience for your audience.

                            Our Web Design service starts with a meticulous understanding of your brand identity and objectives. We craft visually stunning websites that are not only aesthetically pleasing but also seamlessly functional. Utilizing the latest design trends and responsive frameworks, we ensure your website adapts flawlessly to various devices, providing an optimal user experience.
                            <br></br><br></br>

                            Moving beyond aesthetics, our Web Development service delves into the intricacies of creating robust, feature-rich websites. Whether it's an e-commerce platform, corporate site, or a dynamic web application, our development team employs scalable and secure solutions tailored to your specific needs. We prioritize user-friendly interfaces, ensuring visitors navigate effortlessly through your digital space.
                            <br></br><br></br>

                            At Warakah Design & Print, we understand the importance of a website as a powerful marketing tool. Our Web Design & Development services aim to enhance your online visibility, drive engagement, and convert visitors into customers. With a focus on innovation and functionality, we create digital experiences that resonate with your audience, leaving a lasting impression on the digital landscape. Choose Warakah Design & Print for a digital transformation that aligns with your brand's vision and accelerates your online success.
                        </p>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Services