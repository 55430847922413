import React, { useState, useEffect } from 'react';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { experimentalStyled as styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import { Grid } from '@mui/material';
import './offset.css';
import Img1 from '../images/catalog.JPG';
import Img2 from '../images/kat.JPG';
import Img3 from '../images/Foto png/vizitkarta.JPG';
import Img4 from '../images/Foto png/vizitkarta2.JPG';
import Img5 from '../images/flyer.jpg';
import Img6 from '../images/avgr.jpg';
import Img7 from '../images/pizacarddd.jpg';
import Img8 from '../images/carddd.jpg';
import Img9 from '../images/Foto png/calendar egs.JPG';
import Img10 from '../images/Foto png/calendar beni2.JPG';
import Img11 from '../images/Foto png/calendar beni.JPG';
import Img12 from '../images/lskflyer.JPG';
import Img13 from '../images//cardberber.jpg';
import Img14 from '../images/raii.jpg';
import Img15 from '../images/Foto png/vizitkarta1.JPG';
import Img16 from '../images/rosee.jpg';
import 'animate.css';



const productData = [
    {
        id: 1,
        image: Img1,
        title: 'CATALOGS',
        description: 'Description for Product 1',
    },
    {
        id: 2,
        image: Img2,
        title: 'CATALOGS',
        description: 'Description for Product 1',
    },
    {
        id: 3,
        image: Img3,
        title: 'BUSINESS CARDS',
        description: 'Description for Product 1',
    },
    {
        id: 4,
        image: Img4,
        title: 'BUSINESS CARDS',
        description: 'Description for Product 1',
    },
    {
        className: 'pr , imagess',
        id: 5,
        image: Img5,
        title: 'FLYERS',
        description: 'Description for Product 1',
    },
    {
        className: 'pr , imagess',
        id: 6,
        image: Img6,
        title: 'FLYERS',
        description: 'Description for Product 1',
    },
    {
        className: 'pr , imagess',
        id: 7,
        image: Img7,
        title: 'BUSINESS CARDS',
        description: 'Description for Product 1',
    },
    {
        className: 'pr , imagess',
        id: 8,
        image: Img8,
        title: 'BUSINESS CARDS',
        description: 'Description for Product 1',
    },
    {
        id: 9,
        image: Img9,
        title: 'CATALOGS',
        description: 'Description for Product 1',
    },
    {
        id: 10,
        image: Img10,
        title: 'CATALOGS',
        description: 'Description for Product 1',
    },
    {
        id: 11,
        image: Img11,
        title: 'CATALOGS',
        description: 'Description for Product 1',
    },
    {
        id: 12,
        image: Img12,
        title: 'CATALOGS',
        description: 'Description for Product 1',
    },
    {
        className: 'pr , imagess',
        id: 13,
        image: Img13,
        title: 'BUSINESS CARDS',
        description: 'Description for Product 1',
    },
    {
        className: 'pr , imagess',
        id: 14,
        image: Img14,
        title: 'BUSINESS CARDS',
        description: 'Description for Product 1',
    },
    {
        className: 'pr , imagess',
        id: 15,
        image: Img15,
        title: 'BUSINESS CARDS',
        description: 'Description for Product 1',
    },
    {
        className: 'pr , imagess',
        id: 16,
        image: Img16,
        title: 'BUSINESS CARDS',
        description: 'Description for Product 1',
    },
];

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    transition: 'opacity 0.5s ease-in-out', // Add a transition effect
    border: 'none',
}));

// ... (other imports)

function DigitalPrinting() {
    const [productsVisible, setProductsVisible] = useState(false);

    useEffect(() => {
        // Set products to be visible after 0.5 seconds
        const timer = setTimeout(() => {
            setProductsVisible(true);
        }, 500);

        // Clear the timer to avoid memory leaks
        return () => clearTimeout(timer);
    }, []);

    return (
        <div className="container">

            <h1 className='h11'>DIGITAL PRINTING</h1>

            <Grid container spacing={1} className='grid' >
                <TransitionGroup component={null}>
                    {productData.map((product) => {
                        const isStyledProduct = [5, 6, 7, 8, 14, 15, 16, 17, 18].includes(product.id);
                        const productClasses = isStyledProduct ? 'product-grid isStyledProduct' : 'product-grid';

                        return (
                            <CSSTransition
                                key={product.image}
                                timeout={500}
                                classNames="fade"
                                appear={true}
                            >
                                <Grid item xs={12} sm={12} md={4} lg={3}>
                                    <div className={productClasses}>
                                        <div className={`grid-content ${productsVisible ? 'visible' : ''}`}>
                                            <div className='grid-items'>
                                                <img
                                                    src={product.image}
                                                    alt='img'
                                                    id="imgg"
                                                    style={{
                                                        height: isStyledProduct ? '220px' : '100%',
                                                        opacity: productsVisible ? 1 : 0,
                                                    }}
                                                />
                                                {/* Add your Typography components if needed */}
                                            </div>
                                        </div>
                                    </div>
                                </Grid>
                            </CSSTransition>
                        );
                    })}
                </TransitionGroup>
            </Grid>
        </div>
    );
}

export default DigitalPrinting;
