import React, { useState, useEffect } from 'react';
import Img2 from '../images/img/luxx3.JPG';
import Img1 from '../images/img/lluux.JPG';
import Img3 from '../images/img/luxpp.JPG';
import Img4 from '../images/img/luxxxp.JPG';
import Img5 from '../images/img/ppi.JPG';
import Img6 from '../images/img/piza.JPG';
import Img7 from '../images/img/pizzzaa.JPG';
import Img8 from '../images/img/pppp.JPG';
import Img9 from '../images/img/coffe.JPG';
import Img10 from '../images/img/ccofe.JPG';
import Img11 from '../images/img/cofffe.JPG';
import Img12 from '../images/img/ccc.JPG';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { experimentalStyled as styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import { Grid } from '@mui/material';
import './offset.css';

const productData = [
    {
        id: 1,
        image: Img1,
        title: 'NOTEBOOKS',
        description: 'Description for Product 1',
    },
    {
        id: 2,
        image: Img2,
        title: 'BUSSINES CARDS',
        description: 'Description for Product 2',
    },
    {
        id: 3,
        image: Img3,
        title: 'BILL BOOKS',
        description: 'Description for Product 3',
    },
    {
        id: 4,
        image: Img4,
        title: 'CATALOGS',
        description: 'Description for Product 4',
    },
    {
        id: 5,
        image: Img5,
        title: 'CATALOGS',
        description: 'Description for Product 4',
    },
    {
        id: 6,
        image: Img6,
        title: 'CATALOGS',
        description: 'Description for Product 4',
    },
    {
        id: 7,
        image: Img7,
        title: 'CATALOGS',
        description: 'Description for Product 4',
    },
    {
        id: 8,
        image: Img8,
        title: 'CATALOGS',
        description: 'Description for Product 4',
    },
    {
        id: 8,
        image: Img9,
        title: 'CATALOGS',
        description: 'Description for Product 4',
    },
    {
        id: 8,
        image: Img10,
        title: 'CATALOGS',
        description: 'Description for Product 4',
    },
    {
        id: 8,
        image: Img11,
        title: 'CATALOGS',
        description: 'Description for Product 4',
    },
    {
        id: 8,
        image: Img12,
        title: 'CATALOGS',
        description: 'Description for Product 4',
    },
    // Add more products here
];

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    transition: 'opacity 0.5s ease-in-out', // Add a transition effect
    border: 'none',
}));

// ... (other imports)

function Vinyl() {
    const [productsVisible, setProductsVisible] = useState(false);

    useEffect(() => {
        // Set products to be visible after 0.5 seconds
        const timer = setTimeout(() => {
            setProductsVisible(true);
        }, 500);

        // Clear the timer to avoid memory leaks
        return () => clearTimeout(timer);
    }, []);

    return (
        <div className="container">
            <h1 className='h11'>WRAPPING</h1>

            <Grid container spacing={1} className='grid'>
                <TransitionGroup component={null}>
                    {productData.map((product) => {
                        const isStyledProduct = [5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18].includes(product.id);
                        const productClasses = isStyledProduct ? 'product-grid isStyledProduct' : 'product-grid';

                        return (
                            <CSSTransition
                                key={product.image}
                                timeout={500}
                                classNames="fade"
                                appear={true}
                            >
                                <Grid item xs={12} sm={12} md={4} lg={3}>
                                    <div className={productClasses}>
                                        <div className={`grid-content ${productsVisible ? 'visible' : ''}`}>
                                            <div className='grid-items'>
                                                <img
                                                    src={product.image}
                                                    alt='img'
                                                    id="imgg"
                                                    style={{
                                                        opacity: productsVisible ? 1 : 0,
                                                    }}
                                                />
                                                {/* Add your Typography components if needed */}
                                            </div>
                                        </div>
                                    </div>
                                </Grid>
                            </CSSTransition>
                        );
                    })}
                </TransitionGroup>
            </Grid>
        </div>
    );
}

export default Vinyl;
